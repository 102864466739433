import { useTranslation } from "react-i18next";
import bgIconImg from '@images/bg-for-icon-weKnow.svg'
import iconImg from '@images/icon-weKnow.png'

export const WeKnowIt = () => {
    const { t } = useTranslation();
    return (
          <div className="w-full py-[46px] flex flex-col gap-[64px] items-center">
            <h2 className="text-gray900 font-brevia text-[38px] font-semibold uppercase md:hidden sm:hidden xsm:hidden">
              {t("weKnowItSectionTexts.title")}
            </h2>
            <div className="max-w-[1280px] px-[84px] flex justify-center md:px-[42px] sm:px-[42px] xsm:px-[42px]">
              <div className="relative py-[64px] px-[32px] bg-darkblue700 flex gap-[32px] rounded-[16px] md:flex-col xmd:flex-col sm:flex-col xsm:flex-col items-center">
                <img className="absolute -bottom-20 -right-20 w-[150px] md:w-[100px] sm:w-[100px] xsm:w-[100px] md:-bottom-10 sm:-bottom-10 xsm:-bottom-10 md:-right-8 sm:-right-8 xsm:-right-8" src={bgIconImg} alt="Background icon" />
                <img className="absolute -bottom-9 -right-12 w-[80px] md:w-[70px] sm:w-[70px] xsm:w-[70px] md:-bottom-5 sm:-bottom-5 xsm:-bottom-5 md:-right-5 sm:-right-5 xsm:-right-5" src={iconImg} alt="Icon" />
                <h2 className="text-gray200 font-brevia text-[30px] font-semibold uppercase hidden self-stretch text-center md:block sm:block xsm:block">
                  {t("weKnowItSectionTexts.title")}
                </h2>
                <div className="flex flex-col items-center gap-[20px]">
                  <h3 className="text-[#FFF] font-brevia text-[24px] font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph1")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-[18px] font-medium">
                    {t("weKnowItSectionTexts.paragraph1")}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-[20px]">
                  <h3 className="text-[#FFF] font-brevia text-[24px] font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph2")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-[18px] font-medium">
                    {t("weKnowItSectionTexts.paragraph2")}
                  </p>
                </div>
                <div className="flex flex-col items-center gap-[20px]">
                  <h3 className="text-[#FFF] font-brevia text-[24px] font-semibold uppercase self-stretch text-center">
                    {t("weKnowItSectionTexts.titleParagraph3")}
                  </h3>
                  <p className="self-stretch text-gray200 text-center font-poppins text-[18px] font-medium">
                    {t("weKnowItSectionTexts.paragraph3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
    )
}
