import React, { useEffect, useState } from 'react';
import ldClient from './components/launch-darkly-client';
import { NavBar } from './components/navbar/navbar';
import { FreeTrialBanner } from './components/home/free-trial-card/free-trial-banner';
import { Footer } from './components/footer/footer';
import { CookieBanner } from './components/cookie-bar/cookie-banner';
import { GoogleTagManager } from './components/google-tag-manager';
import { HomePageComponent } from './components/home/home';
import { Prodotto } from './components/prodotto/prodotto';
import { Routes, Route, Navigate } from 'react-router-dom'; 
import { ContactsPageComponent } from './components/contacts/contacts';
import { AIToolsPageComponent } from './components/ai-tools/ai-tools';

export const App: React.FC = () => {
  const [flags, setFlags] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const handleFlags = () => {
      const allFlags = ldClient.allFlags();
      setFlags(allFlags);
    };
    ldClient.on('ready', handleFlags);
    ldClient.on('change', handleFlags);

    return () => {
      ldClient.off('ready', handleFlags);
      ldClient.off('change', handleFlags);
      ldClient.close();
    };
  }, []);

  return (
    <>
      <GoogleTagManager />
      <CookieBanner />
      <NavBar />
      <div className='w-full mx-auto'>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} /> 
          <Route path="/home" element={<HomePageComponent flags={flags} />} />
          <Route path="/product" element={<Navigate to='/prodotto' />} />
          <Route path="/prodotto" element={<Prodotto flags={flags} />} />
          <Route path="/contacts" element={<Navigate to='/contatti' />} />
          <Route path="/contatti" element={<ContactsPageComponent flags={flags} />} />
          <Route path="/aiTools" element={<AIToolsPageComponent flags={flags} />} />
        </Routes>
      </div>
      <div className='w-full'>
        <FreeTrialBanner />
        <Footer />
      </div>
    </>
  );
};
