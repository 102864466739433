import { useTranslation } from "react-i18next"
import { Marquee } from "../home/banner-scroll/banner-scroll"
import iconWhatsAppImg from "@images/whatsapp-icon-ai-tools.svg"
import iconFikoImg from "@images/icon-fiko-ai-tools-page.png"

export const AIToolsPageComponent: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
    const { t } = useTranslation();
    const { 
      REACT_APP_WHATSAPP_NUMBER: whatsappNumber,
      REACT_APP_WHATSAPP_TEXT2: whatsappText1,
      REACT_APP_WHATSAPP_TEXT2: whatsappText2,
      REACT_APP_WHATSAPP_TEXT3: whatsappText3,
      REACT_APP_WHATSAPP_TEXT6: whatsappText6,
      REACT_APP_WHATSAPP_TEXT7: whatsappText7,
     } = process.env;

    return (
        <div className="relative w-full flex justify-center overflow-hidden">
          <div className="max-w-[1634px] w-full flex flex-col">
            <div className="w-full flex flex-col gap-4 items-center px-[16px]">
              <h1 className="text-gray900 font-bold text-display-md font-brevia uppercase text-center">
                Metti alla prova ciccio!
              </h1>
              <p className="text-gray600 font-regular text-xl font-poppins text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className='w-full absolute left-0 top-36 md:top-60 sm:top-72 xsm:top-64'>
                <Marquee />
            </div>
            <div className="relative mt-36 flex flex-col items-center gap-[64px] py-[96px] md:gap-[32px] sm:gap-[32px] xsm:gap-[32px]">
              <div className="flex gap-[32px] md:flex-col sm:flex-col xsm:flex-col">
                <div className="flex flex-col gap-[24px]">
                  <div className="w-[280px] h-[296px] bg-gray300"></div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-gray900 font-semibold text-xl font-poppins">
                      {t("footer.aitool.1")}
                    </h2>
                    <p className="text-primary700 font-regular text-lg font-poppins">
                      lorem ipsum
                    </p>
                  </div>
                  <a className="max-w-[150px] flex gap-2 items-center justify-center p-[5px] rounded-sm border border-gray-300 text-gray700 font-semibold text-sm font-poppins md:max-w-full sm:max-w-full xsm:max-w-full" href={`https://wa.me/${whatsappNumber}?text=${whatsappText2}`} target="_blank">
                    <img className="w-[15px] h-[15px]" src={iconWhatsAppImg} alt="Icon WhatsApp" />
                    Button CTA
                  </a>
                </div>
                <div className="flex flex-col gap-[24px]">
                  <div className="w-[280px] h-[296px] bg-gray300"></div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-gray900 font-semibold text-xl font-poppins">
                      {t("footer.aitool.2")}
                    </h2>
                    <p className="text-primary700 font-regular text-lg font-poppins">
                      lorem ipsum
                    </p>
                  </div>
                  <a className="max-w-[150px] flex gap-2 items-center justify-center p-[5px] rounded-sm border border-gray-300 text-gray700 font-semibold text-sm font-poppins md:max-w-full sm:max-w-full xsm:max-w-full" href={`https://wa.me/${whatsappNumber}?text=${whatsappText1}`} target="_blank">
                    <img className="w-[15px] h-[15px]" src={iconWhatsAppImg} alt="Icon WhatsApp" />
                    Button CTA
                  </a>
                </div>
                <div className="flex flex-col gap-[24px]">
                  <div className="w-[280px] h-[296px] bg-gray300"></div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-gray900 font-semibold text-xl font-poppins">
                      {t("footer.aitool.3")}
                    </h2>
                    <p className="text-primary700 font-regular text-lg font-poppins">
                      lorem ipsum
                    </p>
                  </div>
                  <a className="max-w-[150px] flex gap-2 items-center justify-center p-[5px] rounded-sm border border-gray-300 text-gray700 font-semibold text-sm font-poppins md:max-w-full sm:max-w-full xsm:max-w-full" href={`https://wa.me/${whatsappNumber}?text=${whatsappText3}`} target="_blank">
                    <img className="w-[15px] h-[15px]" src={iconWhatsAppImg} alt="Icon WhatsApp" />
                    Button CTA
                  </a>
                </div>
              </div>
              <div className="relative flex gap-[32px] md:flex-col sm:flex-col xsm:flex-col">
                <div className="flex flex-col gap-[24px]">
                  <div className="w-[280px] h-[296px] bg-gray300"></div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-gray900 font-semibold text-xl font-poppins">
                      {t("footer.aitool.4")}
                    </h2>
                    <p className="text-primary700 font-regular text-lg font-poppins">
                      lorem ipsum
                    </p>
                  </div>
                  <a className="max-w-[150px] flex gap-2 items-center justify-center p-[5px] rounded-sm border border-gray-300 text-gray700 font-semibold text-sm font-poppins md:max-w-full sm:max-w-full xsm:max-w-full" href={`https://wa.me/${whatsappNumber}?text=${whatsappText6}`} target="_blank">
                    <img className="w-[15px] h-[15px]" src={iconWhatsAppImg} alt="Icon WhatsApp" />
                    Button CTA
                  </a>
                </div>
                <div className="flex flex-col gap-[24px]">
                  <div className="w-[280px] h-[296px] bg-gray300"></div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-gray900 font-semibold text-xl font-poppins">
                      {t("footer.aitool.5")}
                    </h2>
                    <p className="text-primary700 font-regular text-lg font-poppins">
                      lorem ipsum
                    </p>
                  </div>
                  <a className="max-w-[150px] flex gap-2 items-center justify-center p-[5px] rounded-sm border border-gray-300 text-gray700 font-semibold text-sm font-poppins md:max-w-full sm:max-w-full xsm:max-w-full" href={`https://wa.me/${whatsappNumber}?text=${whatsappText7}`} target="_blank">
                    <img className="w-[15px] h-[15px]" src={iconWhatsAppImg} alt="Icon WhatsApp" />
                    Button CTA
                  </a>
                </div>
                <img className="w-[300px] absolute -bottom-24 -right-80 lg:w-[200px] lg:-bottom-10 lg:-right-40 xmd:w-[200px] xmd:-bottom-10 xmd:-right-40 md:hidden sm:hidden xsm:hidden" src={iconFikoImg} alt="Icon Fiko" />
              </div>
            </div>
          </div>
        </div>
    );
};
