import { useTranslation } from "react-i18next";
import forRestaurateursLightningImg from "@images/for-restaurateurs-lightning-icon.png";
import forRestaurateursMessagesImg from "@images/for-restaurateurs-messages-icon.png";
import forRestaurateursGreenWavesImg from "@images/for-restaurateurs-green-waves.png";

export const ForRestaurateurs = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full pt-[64px] pb-[112px] bg-primary50 flex justify-center">
        <div className="max-w-[1634px] flex flex-col gap-[48px] lg:flex-row justify-center xl:flex-row justify-center 2xl:flex-row justify-center">
          <h2 className="text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase px-[16px]">
            {t("forRestaurateursSectionTexts.title")}
          </h2>
          <div className="w-[50%] flex flex-col gap-[48px] md:w-full sm:w-full xsm:w-full">
            <div className="flex px-[16px] gap-[16px]">
              <img className="w-[40px] h-[40px]" src={forRestaurateursMessagesImg} alt="Messages Icon" />
              <div className="w-full px-[16px] flex flex-col">
                <h3 className="font-poppins text-[18px] font-semibold text-gray900">
                  {t("forRestaurateursSectionTexts.titleParagraph1")}
                </h3>
                <p className="text-gray600 font-poppins text-[16px] font-regular">
                  {t("forRestaurateursSectionTexts.paragraph1")}
                </p>
              </div>
            </div>
            <div className="flex px-[16px] gap-[16px]">
              <img className="w-[40px] h-[40px]" src={forRestaurateursLightningImg} alt="Lightning Icon" />
              <div className="w-full px-[16px] flex flex-col">
                <h3 className="font-poppins text-[18px] font-semibold text-gray900">
                  {t("forRestaurateursSectionTexts.titleParagraph2")}
                </h3>
                <p className="text-gray600 font-poppins text-[16px] font-regular">
                  {t("forRestaurateursSectionTexts.paragraph2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="absolute right-0 mt-[64px]" src={forRestaurateursGreenWavesImg} alt="Green waves" />
    </>
  );
};
