import { useTranslation } from "react-i18next";
import { Button } from "../../reusable/button";
import { BannerNews } from "../../reusable/banner-news";

export const HeroProducts = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col xmd:px-20 px-32 xsm:px-5 sm:p-7 md:p-7 xmd:py-10 lg:items-center xl:items-center 2xl:items-center">
      <div className="w-[60%] xsm:w-full sm:w-full md:w-full xmd:w-full flex justify-center flex-col md:px-8">
        <div className="text-center">
          <div className="w-full flex justify-center">
            <BannerNews />
          </div>
          <h1 className="text-gray900 text-display-lg xsm:text-display-md sm:text-display-md md:text-display-md xmd:text-display-md font-bold font-brevia leading-snug uppercase">
            {t("heroProductsSectionTexts.title")}
          </h1>
          <div className="my-4 w-[70%] w-full">
            <p className="text-gray600 text-xl xsm:text-lg sm:text-lg md:text-lg font-regular font-poppins">
              {t("heroProductsSectionTexts.paragraphPart1")}
            </p>
          </div>
          <div className="py-7 font-poppins font-regular">
            <div className="flex flex-col xmd:flex-row lg:flex-row xl:flex-row 2xl:flex-row w-full justify-center gap-3 text-md font-semibold">
              <Button text={t("heroProductsSectionTexts.button")} isColoured={true} />
              <Button text={t("heroProductsSectionTexts.button2")} isColoured={false} link="#hubspotForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
