import { useTranslation } from "react-i18next"
import { Review } from "../../reusable/review"
import "./reviews-section-style.css"

export const ReviewsSection = () => {
  const { t } = useTranslation()
  return (
    <div className="mask">
      <div className="mt-16 w-full bg-primary50 flex justify-center gap-[30px] content">
        <div className="py-[64px] flex-col gap-[30px] hidden lg:flex xl:flex 2xl:flex">
          <Review review={t("reviewsSectionTexts.review1")} name={t("reviewsSectionTexts.name1")} />
          <Review review={t("reviewsSectionTexts.review2")} name={t("reviewsSectionTexts.name2")} />
          <Review review={t("reviewsSectionTexts.review3")} name={t("reviewsSectionTexts.name3")} />
        </div>
        <div className="flex flex-col gap-[30px] px-[16px]">
          <Review review={t("reviewsSectionTexts.review4")} name={t("reviewsSectionTexts.name4")} />
          <Review review={t("reviewsSectionTexts.review5")} name={t("reviewsSectionTexts.name5")} />
          <Review review={t("reviewsSectionTexts.review6")} name={t("reviewsSectionTexts.name6")} />
          <Review review={t("reviewsSectionTexts.review7")} name={t("reviewsSectionTexts.name7")} />
        </div>
        <div className="py-[64px] flex-col gap-[30px] hidden lg:flex xl:flex 2xl:flex">
          <Review review={t("reviewsSectionTexts.review8")} name={t("reviewsSectionTexts.name8")} />
          <Review review={t("reviewsSectionTexts.review9")} name={t("reviewsSectionTexts.name9")} />
          <Review review={t("reviewsSectionTexts.review10")} name={t("reviewsSectionTexts.name10")} />
        </div>
      </div>
    </div>
  )
}
