import React, { useEffect, useRef, useState } from "react";
import hamburgerMenu from "@images/hamburger-menu.svg";
import { NavBarModalMobile } from "./navbar-modal-mobile/navbar-modal-mobile";
import restaurantsClubLogo from "@images/restaurants-club-logo.svg";
import { useTranslation } from 'react-i18next';
import ChevronDown from "@images/chevron-down.svg";
import ChevronUp from "@images/chevron-up.svg";
import italianLanguageIcon from "@images/italian-language-icon.svg";
import { Link } from 'react-router-dom';

type MenuVoice = {
  name: string;
  link: string;
};

type Choice = {
  lng: string;
  icon: string;
  language: string;
};

export const NavBar = () => {
  const [openNavBarModal, setOpenNavBarModal] = useState(false);
  const [openAiToolsDropDownMenu, setOpenAiToolsDropDownMenu] = useState(false);
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(italianLanguageIcon);
  const modalRef = useRef<HTMLDivElement>(null);

  const changeLanguage = (choice: Choice) => {
    i18n.changeLanguage(choice.lng);
    setCurrentLanguageIcon(choice.icon);
    setLanguageModalOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setOpenAiToolsDropDownMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="p-8 w-full flex justify-between items-center xmd:justify-between lg:justify-start xl:justify-start 2xl:justify-start xmd:w-[98%] lg:w-[95%] xl:w-[95%] 2xl:w-[95%] xmd:mx-auto lg:mx-auto xl:mx-auto 2xl:mx-auto relative">
      <div className="w-[30%] xsm:w-[50%] sm:w-[50%] md:w-[50%] flex flex-row">
        <img className="min-w-[100px]" src={restaurantsClubLogo} alt="Restaurants Club Logo" />
      </div>
      <nav>
        <div className=" flex flex-row font-poppins text-md text-gray600 font-semibold mx-8 xl:text-sm lg:text-sm xsm:hidden sm:hidden md:hidden xmd:hidden">
          {(t("navBarMenuVoices", { returnObjects: true }) as MenuVoice[]).map((voice, index) => (
            voice.name.toLowerCase() === "blog" ? (
              <a href="https://blog.restaurants.club/blog/#" key={index} className="text-left w-[160px] lg:w-[120px] xl:w-[120px]">
                <span className="w-full">{voice.name}</span>
              </a>
            ) : (
              <Link to={`/${voice.name.toLowerCase()}`} key={index} className="text-left w-[160px] lg:w-[120px] xl:w-[120px]">
                <span className="w-full">{voice.name}</span>
              </Link>
            )
          ))}
          <div className="relative">
            <button onClick={() => setOpenAiToolsDropDownMenu(!openAiToolsDropDownMenu)} className="w-[160px] text-left lg:w-[130px] xl:w-[130px]">
              {t("navBarButton.dropDownMenu")}
            </button>
            <div ref={modalRef} className={`absolute left-0 flex flex-col gap-1 mt-2 backdrop-blur-md bg-white/30 rounded-md transition-transform duration-300 ease-in-out transform origin-top ${openAiToolsDropDownMenu ? 'scale-y-100' : 'scale-y-0'}`}>
              <Link className="text-primary500" to={"/aiTools"}>
                {t("navBarDropDown.freeAiTools")}
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <button onClick={() => setOpenNavBarModal(!openNavBarModal)} className="lg:hidden xl:hidden 2xl:hidden">
        <div className="flex flex-row">
          <img src={hamburgerMenu} alt="Hamburger Menu" />
        </div>
      </button>
      {openNavBarModal && <NavBarModalMobile closeModal={() => setOpenNavBarModal(false)} />}

      <div className="w-full flex flex-row justify-end gap-5 font-poppins font-semibold xsm:hidden sm:hidden md:hidden xmd:hidden">
        <div className="w-full flex flex-row justify-end gap-2 ">
          <button className="w-40 rounded text-md xmd:text-sm xmd:w-32 py-2 m-1 border border-gray300 text-gray700 bg-white">{t('navBarButton.onBoarding')}</button>
          <button className="w-32 rounded py-2 m-1 xmd:w-20 bg-darkblue700 text-white xmd:text-sm">{t('navBarButton.login')}</button>
        </div>
        <button className="w-12 justify-end flex items-center" onClick={() => setLanguageModalOpen(!languageModalOpen)}>
          <img src={currentLanguageIcon} alt="Current Language" />
          {languageModalOpen ? <img src={ChevronUp} alt="Chevron Up" /> : <img src={ChevronDown} alt="Chevron Down" />}
        </button>
        {languageModalOpen && (
          <div className="absolute z-50 bg-white shadow-md mt-14 w-32 font-poppins xmd:text-sm font-semibold xsm:hidden sm:hidden">
            {(t("languages", { returnObjects: true }) as Choice[]).map((choice, index) => (
              <button className="flex gap-x-4 items-center px-4 py-2 w-full text-left xmd:text-sm" key={index} onClick={() => changeLanguage(choice)}>
                <img src={choice.icon} alt="Language Icon" />
                <span>{choice.language}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
