import { Form } from "../hubspot-form/hubspot-from";
import { FeaturesSection } from "./features-section/features-section";
import { ForRestaurateurs } from "./for-restaurateurs/for-restaurateurs";
import { HeroProducts } from "./hero-products-section/hero-products";
import { FaqSection } from "./faq-section/faq-section";
import { ReviewsSection } from "./reviews-section/reviews-section";
import { WeKnowIt } from "./we-know-it/we-know-it";

export const Prodotto: React.FC<{ flags: { [key: string]: boolean } }> = ({ flags }) => {
  return (
    <>
      <div className='w-full mx-auto'>
        <HeroProducts />
        <WeKnowIt />
        <ForRestaurateurs />
        <FeaturesSection />
        <ReviewsSection />
        <FaqSection />
        <Form />
      </div>
    </>
  );
};
